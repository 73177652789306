import * as THREE from "three";

export class NodeChurnTimer extends THREE.Object3D {
    constructor() {
        super();
    }

    dispose() {

    }
}